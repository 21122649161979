import { BaseAPI } from './base.api';

class WithdrawAPI extends BaseAPI {
  controller = '/withdraw';

  withdraw(data: { amount: number }) {
    return this._post<void>('/', { data });
  }
}
const api = new WithdrawAPI();
export default api;
